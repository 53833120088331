<template>
    <my-drawer v-model="visible" :style="{width: '100%'}" :with-nav="false" position="right">
        <div class="MaterialsDetail">
            <van-notice-bar background="#ECF3FE" class="MaterialsDetail__bar" color="#1677FF">
                <i slot="left-icon" class="iconfont icon-dui" style="margin-right: 10px;"></i>
                <span>企业信息已提交，请及时补充实名材料</span>
            </van-notice-bar>
            <div class="MaterialsDetail__container">
                <div class="MaterialsDetail__header">
                    <span class="MaterialsDetail__headerText">
                        注意：支持上传JPG/PNG/JPEG格式文件，单一文件不能大于5MB
                    </span>
<!--                    <van-button size="small" type="info">材料打包分享</van-button>-->
                </div>
                <div class="MaterialsDetail__body">
                    <my-item v-for="(item, index) in materials"
                             :key="index"
                             :label="item.material_name"
                             :required="item.is_must == 1"
                    >
                        <my-upload v-model="item.file" :max-size="5 * 1024 * 1024" :params="{userCode, type: 1}" />
                    </my-item>
                </div>
            </div>
        </div>
        <template #footer>
            <van-button block type="default" @click="$router.back()">返回</van-button>
            <van-button block type="info" @click="onAudit(4)">仅保存</van-button>
            <van-button block type="info" @click="onAudit()">提交审核</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyItem, MyUpload, MyDrawer} from "@/components/MyPlugins";
    import {getNumOpenDoc, submitOrderFile} from "@/api/openOrder";
    import {mapState} from "vuex";

    export default {
        name: "MaterialsDetail",
        components: {MyItem, MyUpload, MyDrawer},
        data() {
            return {
                visible: false,
                materials: []
            }
        },
        computed: {
            ...mapState('user', {
                userCode: state => state.accountInfo.userCode
            })
        },
        beforeRouteLeave(to, from, next) {
            this.visible = false;
            next();
        },
        mounted() {
            this.visible = true;
            this.getDocs();
        },
        methods: {
            getDocs() {
                let {query: {batchCode, batchName, mmfCode}, meta} = this.$route;
                document.title = batchName + meta.title;
                getNumOpenDoc({batchCode, mmfCode, loadCompany: false}).then(res => {
                    let {material_docs, material_seat, seat_must} = res.data || {};
                    let list = [];
                    if (material_docs) {
                        list = Object.values(material_docs).filter(item => item.material_code).map(item => {
                            let {material_code, material_name, file, is_must} = item;
                            let fileLength = file.length;
                            return {
                                material_code,
                                material_name,
                                seat_code: '',
                                file: file.filter(e => e.path).map((v, index) => {
                                    let suffix = v.path.substring(v.path.lastIndexOf("."));
                                    return {
                                        name: `${material_name}${fileLength > 1 ? index + 1 : ''}${suffix}`,
                                        src: v.view_path,
                                        url: v.path
                                    }
                                }),
                                is_must,
                            };
                        });
                    }
                    // 追加目的码信息
                    if (material_seat && material_seat.length > 0) {
                        list.push(...material_seat.map(v => {
                            let file = {name: '', src: '', url: ''};
                            if (v.file) {
                                let {name = '', path = '', view_path = ''} = v.file;
                                file = {name, src: view_path, url: path};
                            }
                            return {
                                material_code: '',
                                material_name: `缴费凭证（${v.seat_num}）`,
                                seat_code: v.seat_code,
                                file,
                                is_must: seat_must == 1 ? 1 : 2,
                            }
                        }));
                    }
                    this.materials = list;
                });
            },
            // 3待审核，4仅保存
            onAudit(audit_status = 3) {
                if (audit_status == 3) {
                    let file = this.materials.find(v => {
                        let isMust = v.is_must == 1;
                        if (!Array.isArray(v.file)) return isMust && !v.file.url;
                        return isMust && v.file.length === 0;
                    });
                    if (file) {
                        this.$toast.fail(`请上传${file.material_name}`);
                        return;
                    }
                }
                if (this.loading) return;
                let {batchCode} = this.$route.query;
                // 实名材料
                let materials = this.materials.filter(v => v.material_code);
                let materialsData = [];
                if (materials.length > 0) {
                    // materialsData = {};
                    materials.forEach(item => {
                        let {material_code, file} = item;
                        // materialsData[material_code] = file.length > 0 ? file.map(v => ({file_url: v.url})) : [{file_url: ''}];
                        if (file.length > 0) {
                            materialsData = [
                                ...materialsData,
                                ...file.map(v => {
                                    return {
                                        material_code,
                                        file_url: v.url
                                    }
                                })
                            ];
                        }
                    });
                }
                // 目的码
                let seats = this.materials.filter(v => v.seat_code);
                let data = {
                    audit_status,
                    batch_code: batchCode,
                    material_file: materialsData,
                    seat: seats.map(seat => {
                        return {
                            seat_code: seat.seat_code,
                            seat_num_img: seat.file ? seat.file.url : ''
                        }
                    }),
                };
                this.loading = true;
                this.$toast.loading({message: '提交中，请稍后...', forbidClick: true, duration: 0});
                submitOrderFile(data).then(() => {
                    this.loading = false;
                    this.$toast.success({
                        message: '提交成功',
                        onClose: () => {
                            if (audit_status == 3) this.$router.push({path: '/number-list'});
                        }
                    });
                }).catch(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MaterialsDetail {
        height: 100%;
        display: flex;
        flex-direction: column;
        &__container {
            flex: 1;
            padding: 16px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        &__header {
            display: flex;
            align-items: center;
            gap: 0 12px;
            &Text {
                font-size: 12px;
                color: #969799;
                flex: 1;
                line-height: 17px;
            }
        }
        &__body {
            flex: 1;
            overflow: auto;
            margin-top: 16px;
            .MyItem:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
