import message from "./message";
// list列表组件
export const MyList = () => import('./list');
// 下拉框
export const MySelect = () => import('./select');
// 区间输入框
export const MyRangeInput = () => import('./range/input');
// 固钉
export const MyAffix = () => import('./affix');
// css table布局组件
export const MyTable = () => import('./table');
// css table布局row组件
export const MyTableRow = () => import('./table/row');
// 提示组件
export const MyAlert = () => import('./alert');
// 消息提示组件
export const MyMessage = message;
// 时间区间输入框
export const MyRangeDate = () => import('./range/date');
// 选项卡
export const MyTab = () => import('./tab');
// 抽屉组件
export const MyDrawer = () => import('./drawer');
// 自定义标签组件
export const MyLabel = () => import('./label');
// 时间线
export const MyTimeline = () => import('./timeline');
export const MyTimelineItem = () => import('./timeline/item');
// 自定义数字键盘
export const MyDigitInput = () => import('./digitInput');
// 步骤条
export const MySteps = () => import('./steps');
// 省市选择
export const MyArea = () => import('./area');
// 下拉选择
export const MySelectPicker = () => import('./selectPicker');
// 级联
export const MyCascader = () => import('./cascader');
// 树形选择
export const MyTreeSelect = () => import('./treeSelect');
// 时间选择器
export const MyDate = () => import('./date');
// 文本链接
export const MyLink = () => import('./link');
// 表单项
export const MyItem = () => import('./item');
// 数字键盘
export const MyNumber = () => import('./number');
// 滚动加载列表
export const MyScrollList = () => import('./scrollList');
// 上传
export const MyUpload = () => import('./upload');
